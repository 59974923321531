<template>
  <div>
    <h6 class="font-weight-bolder">Educational Background</h6>
    <div class="row px-2 mb-4">
      <table class="table table-sm table-bordered small">
        <thead>
          <tr>
            <th>#</th>
            <th>Level</th>
            <th>Course</th>
            <th>School Name</th>
            <th>School Address</th>
            <th>Date Graduated</th>
          </tr>
        </thead>
        <tbody>
        <template v-if="crewInfo.crewEducations">
          <tr v-for="(education,index) in crewInfo.crewEducations">
            <td>{{index + 1}}</td>
            <td>{{education.level ? education.level.toUpperCase() : ''}}</td>
            <td>{{education.course ? education.course.toUpperCase() : ''}}</td>
            <td>{{education.school ? education.school.toUpperCase() : ''}}</td>
            <td>{{education.address ? education.address.toUpperCase() : ''}}</td>
            <td>{{education.date_graduated ? education.date_graduated : ''}}</td>
          </tr>
        </template>

        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CrewEducation",
  computed:{
    ...mapGetters(['crewInfo'])
  }
}
</script>

<style scoped>

</style>
